<template>
    <CheckItemCategory
        :data="checkItemCategoryList"
        v-model:categoryVisible="checkItemCategoryVisible"
        typeName="质检项"
        @onSelect="onChangeCheckItemCategory"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}质检项`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="600px"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-row :span="24" class="margin-bottom">
                <a-col :span="24">
                    <a-form-item
                        label="质检项编码"
                        name="code"
                        :rules="[
                            { required: true, message: '请输入质检项编码' },
                            { validator: checkAllSpace },
                        ]"
                        style="margin-bottom: 20px"
                    >
                        <a-form-item>
                            <div class="contant-item">
                                <a-input
                                    style="width: 72%; margin-right: 20px"
                                    :disabled="sysCreate || Boolean(upDateId)"
                                    v-model:value="formData.code"
                                    :maxlength="100"
                                    showCount
                                ></a-input>
                                <a-checkbox
                                    :disabled="Boolean(upDateId)"
                                    v-model:checked="sysCreate"
                                    v-if="!upDateId"
                                >
                                    系统生成
                                </a-checkbox>
                            </div>
                        </a-form-item>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-form-item
                label="质检项名称"
                name="name"
                :rules="[
                    { required: true, message: '请输入质检项名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="质检项类别" name="categoryName">
                <a-form-item>
                    <SelectInput
                        :title="formData.categoryName"
                        :onClick="() => (checkItemCategoryVisible = true)"
                        @onDelete="() => onChangeCheckItemCategory()"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="质检项属性"
                name="type"
                :rules="[
                    { required: true, message: '请选择质检项属性' },
                    { validator: checkAllSpace },
                ]"
            >
                <Select v-model:value="formData.type" :options="typeOptions" labelKey="valueZh" />
            </a-form-item>

            <a-form-item label="质检项附件" name="files">
                <UploadFile
                    businessType="BAS_QC_ITEM_FILE"
                    :files="formData.files"
                    @onSuccess="fileInfo => onSuccess(fileInfo)"
                    @deleteFile="index => onDeleteFile(index)"
                ></UploadFile>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiCheckItemUpdate, apiCheckItemUpdateIndexData } from "@/api/qualityCheck";
import Select from "@/components/Select";
import CheckItemCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import UploadFile from "@/components/UploadFile";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk"],
    components: {
        Select,
        CheckItemCategory,
        DownOutlined,
        UploadFile,
        SelectInput,
        Select,
    },
    emits: ["update:upDateId", "onUpdateSuccess"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            sysCreate: false,
            typeOptions: [],
            checkItemCategoryList: [],
            checkItemCategoryVisible: false,
            formRef: null,
            formData: {
                id: "",
                name: "",
                code: "",
                type: "",
                categoryCode: "",
                categoryName: "",
                files: [],
            },
        });

        const handleCancel = () => {
            state.visible = false;
            state.formData.id = "";
            state.sysCreate = false;
            state.autoCreateCode = "";
            state.formRef.resetFields();
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let fileIds = state.formData.files.map(item => item?.id);
            let res = await apiCheckItemUpdate({
                ...state.formData,
                fileIds,
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                emit("onUpdateSuccess");
                handleCancel();
            }
        };

        const getIndexData = async id => {
            let res = await apiCheckItemUpdateIndexData(id);
            if (res.status === "SUCCESS") {
                const { typeCandidate, categoryCandidate, editInfo } = res?.data;
                state.typeOptions = typeCandidate;
                state.checkItemCategoryList = categoryCandidate;
                if (editInfo) {
                    Object.keys(state.formData).forEach(key => {
                        state.formData[key] = editInfo ? editInfo?.[key] : "";
                    });
                }
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onChangeCheckItemCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        const onSuccess = fileInfo => {
            state.formData.files = [...state.formData.files, fileInfo];
        };

        const onDeleteFile = index => {
            state.formData.files.splice(index, 1);
        };

        const getOrder = async () => {
            let code = await getAutoCreateCode("BAS_QC_ITEM");
            state.autoCreateCode = code;
            state.formData.code = code;
        };

        watch(
            () => state.sysCreate,
            newVal => {
                if (newVal && !state.autoCreateCode) {
                    getOrder();
                } else {
                    state.formData.code = state.autoCreateCode;
                }
                if (!newVal) state.formData.code = "";
            }
        );

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    state.category = "";
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onChangeCheckItemCategory,
            onSuccess,
            onDeleteFile,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
</style>
