<template>
    <a-drawer
        v-model:visible="visible"
        :closable="false"
        :maskClosable="false"
        title="质检项详情"
        height="100%"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="" :column="2">
            <a-descriptions-item label="质检项编码">{{ infoData?.name }}</a-descriptions-item>
            <a-descriptions-item label="质检项名称">{{ infoData?.code }}</a-descriptions-item>
            <a-descriptions-item label="质检项类别">
                {{ infoData?.categoryName }}
            </a-descriptions-item>
            <a-descriptions-item label="质检项属性">{{ infoData?.typeZh }}</a-descriptions-item>
            <a-descriptions-item label="质检项附件" :span="2">
                <div class="filebox">
                    <a v-for="item in infoData?.files" :href="`${fileURL}/download/${item?.id}`">
                        {{ item?.fileName }}
                    </a>
                </div>
            </a-descriptions-item>
            <a-descriptions-item label="创建日期">{{ infoData?.createdTime }}</a-descriptions-item>
            <a-descriptions-item label="创建人">
                {{ infoData?.creatorDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="上次变更时间">
                {{ infoData?.lastUpdateTime }}
            </a-descriptions-item>
            <a-descriptions-item label="变更人">
                {{ infoData?.lastUpdaterDisplayName }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs } from "vue";
import { apiCheckItemDetail } from "@/api/qualityCheck";
import { fileURL } from "@/utils/config";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "工序名称",
        dataIndex: "name",
    },
    {
        title: "工序编码",
        dataIndex: "code",
    },
    {
        title: "工序类型",
        dataIndex: "typeDesc",
    },
    {
        title: "工序类别",
        dataIndex: "categoryName",
    },
    {
        title: "质检要求",
        dataIndex: "needDetect",
    },
    {
        title: "工序附件",
        dataIndex: "stepFileIds",
    },
];

export default defineComponent({
    props: ["detailId"],
    emits: ["update:detailId", "success"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            infoData: null,
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getSetIndexData = async id => {
            let res = await apiCheckItemDetail(id);
            if (res.status === "SUCCESS") {
                state.infoData = res?.data;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getSetIndexData(newVal);
                }
            }
        );

        return {
            fileURL,
            columns,
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
