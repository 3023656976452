<template>
    <div class="container">
        <Detail v-model:detailId="detailId" />
        <FilterBlock @search="search" />
        <UpDate v-model:upDateId="upDateId" @onUpdateSuccess="getTabelData" />
        <UploadData
            uploadUrl="/web/bas-qc-item/import-data"
            downloadUrl="/web/bas-qc-item/download-import-template"
            tips="导入质检项数据,如果数据已存在则进行更新"
            v-model:visible="showUploadDataModal"
            @uploadSuccess="getTabelData"
        />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1200 }"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'published'">
                        <a-switch
                            v-perm="'btn:bas-qc-item-mgr-publish'"
                            :checked="record.published"
                            @change="enabled => changeSwitch(record.published, record.id)"
                        />
                    </template>
                    <template v-if="column.dataIndex === 'files'">
                        <div class="filebox">
                            <a
                                v-for="item in record?.files"
                                :href="`${fileURL}/download/${item?.id}`"
                            >
                                {{ item?.fileName }}
                            </a>
                        </div>
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailId = record.id">详情</a>
                            <a v-perm="'btn:bas-qc-item-mgr-edit'" @click="handleUpdate(record.id)">
                                编辑
                            </a>
                            <a v-perm="'btn:bas-qc-item-mgr-delete'" @click="deleteRow(record.id)">
                                删除
                            </a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-space direction="horizontal" :size="8">
                            <a-button
                                type="primary"
                                v-perm="'btn:bas-qc-item-mgr-add'"
                                @click="handleUpdate('')"
                            >
                                新增
                            </a-button>
                            <a-button
                                v-perm="'btn:bas-qc-item-mgr-import'"
                                @click="showUploadDataModal = true"
                            >
                                导入
                            </a-button>
                        </a-space>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import { confirmModal, showMessage } from "@/utils/common";
import { apiCheckItemList, apiCheckItemDelete, apiCheckItemEnable } from "@/api/qualityCheck";
import { fileURL } from "@/utils/config";
import UploadData from "@/components/UploadData1";
import UpDate from "./upDate";
export default defineComponent({
    components: {
        FilterBlock,
        UpDate,
        Detail,
        UploadData,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "质检项编码",
                dataIndex: "code",
            },
            {
                title: "质检项名称",
                dataIndex: "name",
            },
            {
                title: "版本号",
                dataIndex: "versionShow",
                width: 150,
            },
            {
                title: "质检项类别",
                dataIndex: "categoryName",
            },
            {
                title: "质检项属性",
                dataIndex: "typeZh",
                width: 100,
            },
            {
                title: "附件",
                dataIndex: "files",
            },
            {
                title: "是否发布",
                dataIndex: "published",
                key: "published",
                width: 100,
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 150,
            },
        ];

        const state = reactive({
            showUploadDataModal: false,
            detailId: null,
            upDateId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiCheckItemList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "质检项取消发布后，之后将无法在业务流程中使用该质检项信息，是否确认取消发布？"
                : "质检项发布后，该质检项可以在业务流程中使用，是否确认发布？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiCheckItemEnable(id, enabled);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const deleteRow = id => {
            confirmModal({
                type: "warning",
                content: "质检项删除后将无法恢复，是否确认删除该质检项？",
                onOk: async () => {
                    const {
                        pagination: { current },
                        data,
                    } = state;
                    let res = await apiCheckItemDelete([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        if (current != 1 && data.length == 1) {
                            state.pagination.current--;
                        }
                        getTabelData();
                    }
                },
            });
        };

        const handleUpdate = id => {
            state.upDateId = id;
        };

        return {
            columns,
            ...toRefs(state),
            fileURL,
            getTabelData,
            changeSwitch,
            deleteRow,
            search,
            paginationChange,
            handleUpdate,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
        width: 100%;
    }
}
</style>
